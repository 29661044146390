// =====================================================================
// Contact.scss
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

// Block
//--------------------------------------------------------------------------
.Contact {

}

// Elements
//--------------------------------------------------------------------------
.Contact {
  &__content {
    margin: 1rem 0;
    display: grid;
    gap: 1rem;

    @include breakpoint(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden; // Ensures the zoomed image does not overflow out of the item
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    a {
      color: $primary-color;
    }

    &:hover {
      img {
        transform: scale(1.02);
      }
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 0.5rem;
      transition: transform 0.3s ease;
    }
  }

  path {
    transition: color 0.2s ease;
  }

  &__social-instagram {
    &:hover {
      path {
        color: #E1306C;
      }
    }
  }

  &__social-facebook {
    &:hover {
      path {
        color: #405DE6;
      }
    }
  }

}