// =====================================================================
// Footer.scss
// styles for Header component
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

// Block
//--------------------------------------------------------------------------
.Footer {
  position: relative;
  background-color: #B67233;
  color: white;
}

// Elements
//--------------------------------------------------------------------------
.Footer {
  &__content {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    border-bottom: 1px solid white;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;

    > * {
      flex: 1;
    }

    @include breakpoint(medium) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 20px 40px;
    }
  }

  &__logo {
    color: white;
    font-size: 40px;
    h3 {
      margin: 20px 0;
    }
  }

  &__rights {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
  }
  a {
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }

  &__contact {
    @include breakpoint(medium) {
      text-align: left;
    }
  }

  &__socials {
    @include breakpoint(medium) {
      text-align: right;
    }
  }
}
