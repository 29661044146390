// =====================================================================
// Gallery.scss
// styles for Header component
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

// Block
//--------------------------------------------------------------------------
.Gallery {
  margin-bottom: 100px;
}

// Elements
//--------------------------------------------------------------------------
.Gallery {
  &__content {
    margin: 1rem 0;
    display: grid;
    gap: 1rem;

    @include breakpoint(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(medium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden; // Ensures the zoomed image does not overflow out of the item
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.02);
      }
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    img {
      max-width: 100%;
      height: 380px;
      border-radius: 0.5rem;
      transition: transform 0.3s ease;
      object-fit: cover;
    }
  }

  &__item-title {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1.2rem;
    color: #333;
  }

  &__item-date {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1rem;
    color: rgba(51, 51, 51, 0.65);
  }

  &__item-description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}

@media (min-width: 768px) {
  .Gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .Gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
