// utils/_mixins.scss
@mixin breakpoint($breakpoint) {
  @if $breakpoint == small {
    @media (min-width: 576px) { @content; }
  } @else if $breakpoint == medium {
    @media (min-width: 768px) { @content; }
  } @else if $breakpoint == large {
    @media (min-width: 992px) { @content; }
  } @else if $breakpoint == xlarge {
    @media (min-width: 1200px) { @content; }
  }
}