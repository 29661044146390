// utils/_mixins.scss
h1, h2, h3, h4, h5, h6 {
  font-family: "Signika", sans-serif;
}

h2 {
  color: black;
  margin: 40px 0;
  text-align: center;
  font-size: 30px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    background-color: #B67233;
    margin: 0 auto;
  }

  @include breakpoint(medium) {
    font-size: 40px;
  }

  @include breakpoint(large) {
    font-size: 50px;
  }

  @include breakpoint(xlarge) {
    font-size: 60px;
  }
}