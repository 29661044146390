// =====================================================================
// Contact.scss
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

// Block
//--------------------------------------------------------------------------
.About {
  margin-bottom: 100px;
}

// Elements
//--------------------------------------------------------------------------
