// =====================================================================
// Header.scss
// styles for Header component
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

.Header {
  position: sticky;
  top: 0;
  z-index: 2;

  @include breakpoint(small) {
  }

  @include breakpoint(medium) {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: center;
  }

  @include breakpoint(xlarge) {
  }

  &__background {
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -1;
    max-width: 2600px;


    @include breakpoint(medium) {
    }

    @include breakpoint(large) {
      top: -20px;
    }

    @include breakpoint(xlarge) {
      top: -50px;
    }
    @media (min-width: 1800px) {
      top: -100px;
    }
    @media (min-width: 2100px) {
      top: -140px;
    }
    @media (min-width: 2200px) {
      top: -155px;
    }
    @media (min-width: 2300px) {
      top: -180px;
    }
  }

}

.wrapper {
  display: block;
}
.section {
  width: 100%;

  &:nth-child(1) {
    min-height: 400px;
  }
  &:nth-child(2) {
    min-height: 600px;

  }
    &:nth-child(3) {
      min-height: 600px;

    }
    &:nth-child(4) {
    }
}