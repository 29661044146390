// =====================================================================
// Navbar.scss
// styles for Header component
// =====================================================================
@import '../utils/variables';
@import '../utils/mixins';

// Block
//--------------------------------------------------------------------------
.Navbar {
  &__logo {
    font-family: "Signika", sans-serif;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-optical-sizing: auto;
    font-size: 30px;
    color: $primary-color;

    //@include breakpoint(medium) {
    //  display: none;
    //}

    @include breakpoint(medium) {
      position: static; // Změní pozicování na statické
      transform: none; // Odstraní transformaci
      top: auto; // Odstraní nastavení top
      left: auto; // Odstraní nastavení left
      font-size: 50px;
      margin-left: 10px;
    }

    @include breakpoint(large) {
      font-size: 60px;
      margin-left: 30px;
    }

    @include breakpoint(xlarge) {
      font-size: 70px;
    }
  }

  &__logo-sm {
    font-family: "Signika", sans-serif;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-optical-sizing: auto;
    font-size: 30px;
    color: $primary-color;


    @include breakpoint(medium) {
      display: none;
    }

  }

  &__menu {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 0;
    height: 50vh;
    background-color: $secondary-color;
    //background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-120%);
    transition: transform 0.3s ease-in-out;

    &--open {
      transform: translateY(0);
    }

    @include breakpoint(medium) {
      margin: 0 auto;
      max-width: 1400px;
      transform: translateY(0);
      height: auto;
      display: flex;
      justify-content: space-between;
      background-color: transparent;
    }
  }

  &__list {
    padding: 0;

    @include breakpoint(medium) {
      display: flex;
    }
  }

  &__list-item {
    margin: 25px 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(medium) {
      margin: 25px 20px;
    }

    @include breakpoint(large) {
      margin: 25px 30px;
    }

    @include breakpoint(xlarge) {
      margin: 35px 40px;
    }

    a {
      text-decoration: none;
      font-size: 1.2rem;
      color: white;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &:active, &:focus, &:visited {
      }
    }

    svg {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }

    &--active {
      a {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }

  &__toggler {
    background-color: $secondary-color;
    width: 20px;
    height: 20px;
    padding: 10px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    right: 10px;
    top: 15px;

    @include breakpoint(medium) {
      display: none;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 80%;
      background: $primary-color;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 9px;
        transform-origin: left center;
      }

      &:nth-child(2) {
        top: 16px;
        transform-origin: left center;
      }

      &:nth-child(3) {
        top: 23px;
        transform-origin: left center;
      }
    }

    &--open {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 4px;
          left: 3px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 26px;
          left: 3px;
        }
      }
    }
  }

  &__chocolate {
    width: 100vw;
    position: absolute;
    bottom: -70px;

    @include breakpoint(medium) {
      display: none;
    }
  }
}