// utils/_variables.scss
$breakpoints: (
        'small': 576px,
        'medium': 768px,
        'large': 992px,
        'xlarge': 1200px
);

// colors
$primary-color: #f54442;
$secondary-color: #261a13;
