// =====================================================================
// main.scss
// Global styles
// =====================================================================

// Block
//--------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Signika:wght@300..700&display=swap');
@import './utils/variables';
@import './utils/mixins';
.App {
  position: relative;
  font-family: "Raleway", sans-serif;
  scroll-behavior: smooth;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    color: black;
}

body {
  margin: 0;
  padding: 0;
}

.Main {
  display: flex;
  justify-content: center;
  padding: 150px 15px 150px 15px;

  @include breakpoint(small) {
    padding: 180px 15px 15px 15px;
  }

  @include breakpoint(medium) {
    padding-top: 200px;
  }

  @include breakpoint(large) {
    padding-top: 250px;
  }

  @include breakpoint(xlarge) {
    padding-top: 300px;
  }
  @media (min-width: 1800px) {
    padding-top: 300px;
  }
  @media (min-width: 2500px) {
    padding-top: 400px;
  }

  &__background {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top:0;
    z-index: -2;
  }

  &__section {
    max-width: 1200px;
    margin-top: 200px;
  }
  section {
    max-width: 1280px;
  }
}

button {
  display: block;
  margin: 2rem auto;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #B67233;
  border: none;
  border-radius:30px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(182, 114, 51, 0.7);
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

.divider {
    height: 4px;
  width: 70px;
    background-color: #B67233;
  text-align: center;
  margin: 0 auto
}

.Donut {
  display: none;
  @media (min-width: 1700px) {
    display: block;
  }

  &:nth-of-type(1) {
    font-size: 200px;
    position: absolute;
    right: -30px;
    bottom: 50vh;
  }
  &:nth-of-type(2) {
    font-size: 170px;
    position: absolute;
    left: -70px;
    bottom: 70vh;
  }
  &:nth-of-type(3) {
    font-size: 80px;
    position: absolute;
    right: 100px;
    top: 30vh;
  }
  &:nth-of-type(4) {
    font-size: 120px;
    position: absolute;
    left: 100px;
    top: 10vh;
  }
  &:nth-of-type(5) {
    font-size: 120px;
    position: absolute;
    left: 60vw;
    bottom: 5vh;
  }
}